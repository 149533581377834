@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");

body,
.home-section {
  margin: 0;
  font-family: "Raleway", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(to left, #2C041C, #e467a5);
  padding: 0;
  box-sizing: border-box;
  max-width: 100%;
  height: 100vh; 
  overflow-x: hidden;
}

